export default {
    handleErrorsAgendamento(errorData, errorForm) {
        let error = errorData.errors;
 
        errorForm.horario_inicio = error['horario_inicio'] ? error['horario_inicio'[0]] : ''
        errorForm.horario_termino = error['horario_termino'] ? error['horario_termino'][0] : ''
        errorForm.id_campanha = error['id_campanha'] ? error['id_campanha'][0] : ''
        errorForm.id_empresa_endereco = error['id_empresa_endereco'] ? error['id_empresa_endereco'][0] : ''
        errorForm.id_empresa = error['id_empresa'] ? error['id_empresa'[0]] : ''
        errorForm.id_unidade = error['id_unidade'] ? error['id_unidade'][0] : ''
        if(typeof error == 'string') {
            errorForm.error = error;
        }
    },
    handleErrorsHours(errorData, errorForm) {
        let error = errorData;
        errorForm.horario_termino = error ? error : ''
        if(typeof error == 'string') {
            errorForm.error = error;
        }
    },
    cleanErrors(errorForm) {
        errorForm.horario_termino = ''
        errorForm.horario_inicio = ''
        errorForm.id_campanha = ''
        errorForm.id_empresa_endereco = ''
        errorForm.id_empresa = ''
        errorForm.id_unidade = ''
    }
}