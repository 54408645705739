export default {
  handlePeriod(startTime, finishTime) {
    let startTimeSplit = startTime.split(':')
    let finishTimeSplit = finishTime.split(':')

    const startTimeMatutino = '06'
    const startTimeVespertino = '12'
    const startTimeNoturno = '18'

    let period = ""
    let periodOptions = []
    let msg = ""

      if(startTimeSplit[0] >= startTimeMatutino && startTimeSplit[0] < startTimeVespertino) {
        if(this.getMatutinoTime(startTimeSplit, finishTimeSplit)) {
          period = {
            id: 1,
            descricao: 'Matutino'
          }
        } else if(finishTimeSplit[0] >= startTimeVespertino && finishTimeSplit[0] < startTimeNoturno) {
          period = [
            {
              id: 1,
              descricao: 'Matutino' 
            },
            {
              id: 2,
              descricao: 'Vespertino'
            }
          ]
        } else if(finishTimeSplit[0] >= startTimeNoturno || (finishTimeSplit[0] < startTimeSplit[0]) ) {
          period = [
            {
              id: 1,
              descricao: 'Matutino'
            },
            {
              id: 2,
              descricao: 'Vespertino'
            },
            {
              id: 3,
              descricao: 'Noturno'
            }
          ]
        }
      }
  
      if(startTimeSplit[0] >= startTimeVespertino && startTimeSplit[0] < startTimeNoturno) {
        if(this.getVespertinoTime(startTimeSplit, finishTimeSplit)) {
          period = {
            id: 2,
            descricao: 'Vespertino'
          }
        } else if((finishTimeSplit[0] >= startTimeNoturno) || (finishTimeSplit[0] < startTimeMatutino )) {
          period = [
            {
              id: 2,
              descricao: 'Vespertino'
            },
            {
              id: 3,
              descricao: 'Noturno'
            }
          ]
        } else if(finishTimeSplit[0] >= startTimeVespertino || (finishTimeSplit[0] < startTimeVespertino)) {
          period = [
            {
              id: 1,
              descricao: 'Vespertino'
            },
            {
              id: 2,
              descricao: 'Noturno'
            },
            {
              id: 3,
              descricao: 'Matutino'
            }
          ]
        }  
      }
  
      if(startTimeSplit[0] >= startTimeNoturno || (startTimeSplit[0] >= '00' && startTimeSplit[0] < startTimeMatutino) ) { 
        if(this.getNightTime(startTimeSplit, finishTimeSplit)) {
          period = {
            id: 3,
            descricao: 'Noturno'
          }
        } else if(finishTimeSplit[0] >= startTimeMatutino && finishTimeSplit[0] < startTimeVespertino) {
          period = [
            {
              id: 3,
              descricao: 'Noturno'
            },
            {
              id: 1,
              descricao: 'Matutino'
            }
          ]
        } else if(finishTimeSplit[0] >= startTimeNoturno && finishTimeSplit[0]<= '23' || (finishTimeSplit[0] >= startTimeVespertino ) || ( finishTimeSplit[0] < startTimeNoturno) ) {
          period = [
            {
              id: 3,
              descricao: 'Noturno'
            }, 
            {
              id: 1,
              descricao: 'Matutino'
            },
            {
              id: 2,
              descricao: 'Vespertino'
            }
          ]
        }
      }
    

    return {period, periodOptions, msg}
  },
  getNightTime(startTime, finishTime) {
    if(startTime && finishTime) {
      return ((startTime[0] >= '18' && (finishTime[0] >= '18' && finishTime[0] <= '23')) && (startTime[0] <= finishTime[0]) && finishTime[1] <= '59') || 
              (((startTime[0] >= '00' &&  startTime[0] <= '05')  && finishTime[0] <= '05') && (finishTime[0] >= startTime[0]) && finishTime[1] <= '59') ||
              ((startTime[0] >= '18' && finishTime[0] <= '05') && finishTime[1] <= '59') 

    }
  },
  getMatutinoTime(startTime, finishTime) {
    if(startTime && finishTime) {
      return startTime[0] >= '06' && (finishTime[0] < '12' && finishTime[0] >= startTime[0]) && finishTime[1] <= '59'
    }
  },
  getVespertinoTime(startTime, finishTime) {
    if(startTime && finishTime) {
      return ( startTime[0] >= '12' && finishTime[0] < '18' && (finishTime[0] >= '12' && finishTime[0] >= startTime[0]) ) && finishTime[1] <= '59' 
    }
  },
}