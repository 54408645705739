export const CampanhaNaoVigenteMixin = {
    data(){
        return {
            showModalCampanhaNaoVigente: false,
            campanhaNaoVigenteConfirmado: false
        }
    },
    methods: {
        verificaSeCampanhaEhVigenteOuConfirmada() {
            if (this.form.campaign.situacao === 'Concluída' && !this.campanhaNaoVigenteConfirmado) {
                this.showModalCampanhaNaoVigente = true
                this.loading = false
                return false
            }
            return true
        },
        confirmedCampanhaNaoVigente(callback) {
            this.campanhaNaoVigenteConfirmado = true
            this.showModalCampanhaNaoVigente = false

            callback();
        },
        canceledCampanhaNaoVigente() {
            this.showModalCampanhaNaoVigente = false
            this.campanhaNaoVigenteConfirmado = false
        }
    }
};